export const dicts = {
  // 活动类型
  activityTypes: [
    { id: '1', name: '注册领取' },
    { id: '2', name: '充值领取' },
    { id: '3', name: '定向发放' },
    { id: '4', name: '充电领取' },
    { id: '5', name: '扫码领取' },
    { id: '6', name: '券码兑换' },
    // { id: '7', name: '拉新活动' },
  ],
};

export const dictDesc = (dictKey, id) => {
  if (!dicts[dictKey]) {
    console.warn(`未知字典名:${dictKey}`);
    return id;
  }
  const target = dicts[dictKey].find(item => Number(item.id) === Number(id));
  if (target) {
    return target.name;
  }
  return '——';
};
